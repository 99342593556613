import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import urls from "./conf/api.urls"
import UrlService from "@/services/technical/UrlService"
import AxiosHelper from "@/helpers/axios.helper"
import LangMixin from "@/mixins/LangMixin"
import i18n from "@/i18n/index"
import CaareaVlib from "caarea-vlib"
import "bootstrap/dist/js/bootstrap.bundle"
import SsoService from "@/services/technical/SsoService"

Vue.config.productionTip = false

UrlService.initialize(urls)
AxiosHelper.initialize()
store.dispatch("config/initI18n")
Vue.mixin(LangMixin)
Vue.use(CaareaVlib) // Lib components are made available globally

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

window.onfocus = async () => {
  // refresh token if necessary on window focus
  SsoService.refreshToken()
}

if (window.Cypress) {
  // Add `__store__` to the window object only when testing with Cypress
  window.__store__ = store
}
