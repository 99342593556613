<template>
  <div v-if="isLoading" class="mt-5">
    <spinner></spinner>
  </div>
  <div v-else class="container vh-100 pt-3">
    <div class="row">
      <div class="col-12 mb-1 bg-1 px-0">
        <router-link
          to="/"
          class="d-flex row text-decoration-none mb-3 back-to-home--w"
        >
          <span class="icon-arrow-left px-2 ml-2"></span>
          <p>{{ $t("contract.back") }}</p>
        </router-link>

        <div class="d-flex flex-row justify-content-between mb-4 px-0">
          <div class="contract-title--w">
            <h1 class="text-white font-weight-bold">
              {{ contract.sale.vehicle.make }}
              {{ contract.sale.vehicle.model }}
            </h1>
            <p class="gilroy-text">
              {{ $t("contract.contract") }} N°
              {{ contract.num }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 h-100">
      <div class="row">
        <div class="col-7 px-0 h-75">
          <ContractDetail :contract="contract" data-cy="contract-detail" />
          <div class="mr-3 mt-3">
            <router-link
              :to="{
                name: 'newClaim',
              }"
              class="text-decoration-none"
            >
              <TopButtonWithIcon
                icon="icon-warning"
                :label="$t('newClaim.buttonLabel')"
              />
            </router-link>
          </div>
        </div>
        <div class="col-5 px-0">
          <VehicleDetail
            :vehicle="vehicle"
            :logo="vehicle.make"
            data-cy="vehicle-detail"
          />
          <MembershipCard class="mb-2" data-cy="membership-card" />
          <ContractDocuments
            :subscription="contract.subscription"
            :documents="contract.documents"
            data-cy="contract-documents"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ContractDetail from "../components/contracts/ContractDetail"
import VehicleDetail from "../components/vehicles/VehicleDetail"
import MembershipCard from "../components/MembershipCard"
import ContractDocuments from "../components/contracts/ContractDocuments"
import TopButtonWithIcon from "@/components/button/TopButtonWithIcon"

export default {
  name: "Contract",
  components: {
    ContractDetail,
    VehicleDetail,
    MembershipCard,
    ContractDocuments,
    TopButtonWithIcon,
  },
  data() {
    return {
      contract: null,
      vehicle: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("contract", ["getContractById", "getContracts"]),
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "contract/loadByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.contract = this.getContractById(this.$route.params.contractId)
        this.vehicle = this.contract.sale.vehicle
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.back-to-home--w {
  color: #fff;
  opacity: 0.7;
  width: 10%;
  &:hover {
    color: gray;
  }
}
.contract-title--w {
  h1 {
    margin-bottom: 0;
  }
  p {
    color: #fff;
    opacity: 0.7;
    font-size: 20px;
    margin-bottom: 0;
  }
}
.btn-event--w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $pink100;
  font-weight: bold;
}
</style>
