<template>
  <nav class="navbar navbar-expand-lg navbar-light h-100">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbarNavDropdown" class="collapse navbar-collapse h-100">
      <ul class="navbar-nav navbar-right h-100">
        <li
          class="nav-item"
          :class="{ active: $route.name === `home` || $route.name === `contract` }"
        >
          <router-link class="nav-link" :to="{ name: 'home' }">
            <span class="icon-home2"></span>
            <br />
            {{ $t("navbar.home") }}
          </router-link>
        </li>
        <li
          class="nav-item"
          :class="{
            active:
              $route.name === `claims` ||
              $route.name === `claimsDetail` ||
              $route.name === `newClaim`,
          }"
        >
          <router-link class="nav-link" :to="{ name: 'claims' }">
            <span class="icon-warning"></span>
            <br />

            {{ $t("navbar.claims") }}
          </router-link>
        </li>
        <li class="nav-item" :class="{ active: $route.name === `mails` }">
          <router-link class="nav-link" :to="{ name: 'mails' }">
            <span class="icon-warning"></span>
            <br />

            {{ $t("navbar.mail") }}
          </router-link>
        </li>
        <li class="nav-item" :class="{ active: $route.name === `faq` }">
          <router-link class="nav-link" :to="{ name: 'faq' }">
            <span class="icon-mailbox-empty"></span>
            <br />
            {{ $t("navbar.faq") }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
}
</script>

<style lang="scss" scoped>
#navbarNavDropdown {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
