<template>
  <div class="mainlayout">
    <div class="mainlayout-content login-frieze">
      <router-view></router-view>
    </div>
    <div class="mainlayout-footer"></div>
  </div>
</template>

<script>
export default {
  name: "NoLayout",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = this.$t(to.meta.title) || "My Caarea"
      },
    },
  },
}
</script>
