import error from "./views/error.json"
import navbar from "./layout/navbar.json"
import footer from "./layout/footer.json"
import topbar from "./layout/topbar.json"
import home from "./views/home.json"
import claims from "./views/claims.json"
import faq from "./views/faq.json"
import contract from "./views/contract.json"
import mails from "./views/mails.json"
import button from "./button.json"
import newClaim from "./views/newClaim.json"
import vehicleInfosModal from "./views/vehicleInfosModal.json"

export default {
  error,
  navbar,
  footer,
  home,
  button,
  topbar,
  claims,
  mails,
  faq,
  contract,
  newClaim,
  vehicleInfosModal,
}
