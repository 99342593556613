<template>
  <header data-cy="top_bar" class="header sticky-top">
    <div class="container h-100">
      <div class="row align-items-center justify-content-between h-100">
        <div class="col-auto">
          <a href="/">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
              alt="Caarea Solutions"
            />
          </a>
        </div>
        <div class="d-flex justify-content-end h-100">
          <NavBar></NavBar>
          <div class="dropdown show">
            <button
              id="languageDropdownMenuButton"
              class="invisible-button dropdown-btn dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentLang }}
            </button>

            <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
              <button
                v-for="lang in languages"
                :key="lang"
                class="dropdown-item dropdown-btn"
                :data-cy="`change-language-btn-${lang}`"
                :disabled="lang === getCurrentLang"
                @click.prevent="onChangeCurrentLanguage(lang)"
              >
                {{ lang }}
              </button>
            </div>
          </div>
          <div
            class="col-auto slide login-icon-wedge h-100"
            :class="{
              'slide-start': expanded,
            }"
          >
            <div
              class="
                dropdown
                d-row d-flex
                justify-content-center
                align-items-center
                h-100
              "
            >
              <button
                id="dropdownMenuButton"
                class="btn"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="https://res.cloudinary.com/caarea/image/upload/v1627981092/cs/assets/profil-picture_sn8tqj.png"
                  :alt="fullname"
                  :title="fullname"
                />
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  data-cy="burger_diconnect_link"
                  :to="{ name: 'logout' }"
                  class="dropdown-item nav-item menu-item"
                >
                  {{ $t("topbar.disconnect") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import NavBar from "./NavBar"
import i18n from "@/i18n/index"

export default {
  name: "TopBar",
  components: { NavBar },
  props: ["expanded"],
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("config", ["getCurrentLang"]),
    currentLang() {
      return this.getCurrentLang
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
    fullname() {
      if (this.getCurrentUser.first_name || this.getCurrentUser.last_name) {
        return this.getCurrentUser.first_name + " " + this.getCurrentUser.last_name
      }
      return this.getCurrentUser.username
    },
  },
  methods: {
    async onChangeCurrentLanguage(newLang) {
      await this.$store.dispatch("config/setCurrentLang", newLang)
      const payload = {
        userId: this.getCurrentUser.id,
        lang: newLang,
      }
      await this.$store.dispatch("auth/updateLang", payload)
      const to = this.$router.resolve({ params: { lang: newLang } })
      await this.$router.push(to.location)
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  border-bottom: 2px solid #efefef;
}
.dropdown-btn {
  height: 100%;
  border: none;
  background-color: transparent;
}
.dropdown-item {
  &:hover,
  &:focus {
    color: $blue100;
    background-color: transparent;
  }
}
.login-icon-wedge {
  width: 76px;
  border: 1px solid #efefef;
  border-top: none;
  border-bottom: none;
}
.slide {
  transition: all 0.3s;
}
.slide-start {
  transform: translateX(-100%);
}
</style>
