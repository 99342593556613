<template>
  <div data-cy="claim-documents">
    <GreyRectangle>
      <h2 class="mb-0 p-3">{{ $t("claims.document.label") }}</h2>
      <slot></slot>
    </GreyRectangle>
    <SelectFileButton
      input-id="doc"
      name="claim_document"
      accept=".pdf, application/pdf, .jpg, application/jpg, .png, application/png"
      :button-label="$t('button.add_document')"
      :uploaded-file="getUploadedFilename"
      @input-file="onInputFile"
    ></SelectFileButton>
  </div>
</template>

<script>
import GreyRectangle from "../base/GreyRectangle"
import SelectFileButton from "@/components/button/SelectFileButton"
import { mapGetters } from "vuex"
export default {
  name: "ClaimDocuments",
  components: { GreyRectangle, SelectFileButton },
  props: {
    claim: {
      type: Object,
    },
  },
  data() {
    return {
      uploadedFile: null,
    }
  },
  computed: {
    getUploadedFilename() {
      return this.uploadedFile !== null ? this.uploadedFile.filename : ""
    },
  },
  methods: {
    async onInputFile(file) {
      this.$emit("input-file", file)
    },
  },
}
</script>

<style scoped></style>
