<template>
  <footer class="fixed-bottom">
    <div class="container h-100">
      <div class="d-flex flex-row justify-content-between align-items-center h-100">
        <div class="col-auto">
          <a href="#" class="mr-5">{{ $t("footer.legal_notice") }}</a>
          <a href="#">{{ $t("footer.privacy_policy") }}</a>
        </div>
        <div class="col-auto">
          &copy; CAAREA. All rights reserved.
          <template v-if="version">v{{ version }}</template>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
}
</script>
