// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"

const state = {
  claims: [],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CLAIMS(state, claims) {
    state.claims = claims
  },
  SET_CLAIM(state, claim) {
    Vue.set(state.claims, claim.id, claim)
  },
}

// ACTIONS (camelCase)
const actions = {
  async loadClaimsByBeneficiaryId({ commit }, beneficiaryId) {
    try {
      const claims = await HttpService.get(
        UrlService.render("claimsByBeneficiaryId", { id: beneficiaryId })
      )
      commit("SET_CLAIMS", claims)
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async createClaim({ commit }, formData) {
    try {
      const response = await HttpService.post(UrlService.render("claims"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      commit("SET_CLAIM", response)
      return response
    } catch (e) {
      console.error("createClaim failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getClaimByNum: (state) => (num) => state.claims.find((claim) => claim.num === num),
  getClaims: (state) => state.claims,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
