<template>
  <GreyRectangle class="p-3">
    <h2>{{ $t("contract.membershipCard") }}</h2>
    <div class="d-flex row px-2 pt-2">
      <span class="icon-document px-2"></span>
      <p>{{ $t("contract.check_information") }}</p>
    </div>
  </GreyRectangle>
</template>

<script>
import GreyRectangle from "./base/GreyRectangle"

export default {
  name: "MembershipCard",
  components: { GreyRectangle },
}
</script>

<style scoped></style>
