<template>
  <div id="accordion" class="col-12">
    <div v-for="(question, key) in questions" :key="key">
      <AccordionCard :question-key="key" :question-details="question"></AccordionCard>
    </div>
  </div>
</template>

<script>
import AccordionCard from "./AccordionCard"
export default {
  name: "AccordionContainer",
  components: { AccordionCard },
  props: {
    questions: { type: Array },
  },
}
</script>

<style scoped></style>
