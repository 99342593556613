<template>
  <div class="container pt-5">
    <ModalWarning
      v-if="mustWarningModalBeDisplayed"
      :title="getWarningModalInfo.title"
      :reason="getWarningModalInfo.reason"
      :advice="getWarningModalInfo.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <div v-if="isLoading" class="mt-5">
      <spinner></spinner>
    </div>
    <div v-else class="row">
      <div class="col-12 mb-3 d-flex justify-content-between align-items-center">
        <div class="col-8">
          <h1>
            <br />
            <span>{{ $t("mails.title") }} ({{ nonReadMessagesNumber }})</span>
            <br />
            <small>{{ $t("mails.subtitle") }}</small>
          </h1>
        </div>
        <div class="form-group mb-0">
          <ModalBootstrap
            v-if="showNewMessageModal"
            id-modal="modalBootstrap"
            :title="$t('mails.newMessage')"
            data-cy="modal-new-message"
            @close="onCloseNewMessageModal"
          >
            <template #body>
              <div>
                <FormTextArea
                  v-model="newMessage.content"
                  value=""
                  name="message_content"
                  :placeholder="$t('mails.placeholder')"
                  data-cy="modal-message-content"
                ></FormTextArea>
                <div
                  v-if="alreadyUploadedDocument"
                  class="alert alert-warning"
                  role="alert"
                >
                  {{ $t("mails.alreadyUploaded") }}
                </div>
                <div>
                  <p v-for="(document, index) in documentsList" :key="index">
                    {{ document.file.name }}
                  </p>
                </div>
                <SelectFileButton
                  input-id="add_mail_doc"
                  :button-label="$t('mails.addDocument')"
                  name="message_document"
                  accept=".pdf, application/pdf, .jpg, application/jpg, .png, application/png"
                  @input-file="onInputFile"
                ></SelectFileButton>
                <spinner v-if="isUploadInProgress"></spinner>
                <CallToActionButton
                  :button-label="$t('mails.send')"
                  :is-disabled="isSubmitButtonDisabled"
                  data-cy="modal-submit-button"
                  @click.prevent="sendNewMessage"
                ></CallToActionButton>
              </div>
            </template>
          </ModalBootstrap>
          <CallToActionButton
            v-else
            :icon="'icon-pencil'"
            :button-label="$t('mails.newMessage')"
            data-cy="new-message-button"
            data-toggle="modal"
            data-target="#modalBootstrap"
            @click="onNewMessageButtonClick"
          ></CallToActionButton>
        </div>
      </div>
      <div class="col-12 mb-3 div-mail-w">
        <div class="chat-container">
          <div
            v-if="messages.length > 0"
            class="chat-box chatContainerScroll"
            data-cy="chat-container-scroll"
          >
            <div v-for="message in messages" :key="message.id">
              <MailDetails :message="message"></MailDetails>
            </div>
          </div>
          <div v-else>
            <h3 data-cy="no-messages">{{ $t("mails.no_messages") }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import MailDetails from "../components/mails/MailDetails"
import CallToActionButton from "../components/button/CallToActionButton"
import SelectFileButton from "../components/button/SelectFileButton"
import ModalBootstrap from "../components/modal/ModalBootstrap"
import ModalWarning from "../components/modal/ModalWarning"

export default {
  name: "Mails",
  components: {
    ModalBootstrap,
    SelectFileButton,
    CallToActionButton,
    MailDetails,
    ModalWarning,
  },
  data() {
    return {
      isLoading: true,
      messages: [],
      messageWriting: false,
      isUploadInProgress: false,
      alreadyUploadedDocument: false,
      newMessage: {
        content: "",
      },
      showNewMessageModal: false,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("messages", ["getMessages"]),
    ...mapGetters("document", ["messageListDocuments", "documentAlreadyUploaded"]),
    ...mapGetters("gui", ["mustWarningModalBeDisplayed", "getWarningModalInfo"]),
    nonReadMessagesNumber() {
      return this.messages.filter((message) => message.read_date === null).length
    },
    documentsList() {
      return this.messageListDocuments
    },
    isSubmitButtonDisabled() {
      return this.newMessage.content.length === 0
    },
  },
  async created() {
    // todo: retirer après test:
    try {
      await this.$store.dispatch("document/clear")
    } catch (e) {
      console.error("failed to clear all documents")
    }
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "messages/loadMessagesByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.messages = this.messagesFilteredByUserFrom()
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
    if (
      this.messages.length > 0 &&
      this.messages.some(
        (message) => message.user_from.id !== this.getCurrentUser.id
      ) &&
      !this.getLastMessageFromCaarea().read_date
    ) {
      try {
        await this.$store.dispatch(
          "messages/update",
          this.getLastMessageFromCaarea().id
        )
      } catch (e) {
        console.error("failed to send message read")
      }
    }
  },
  methods: {
    onCloseWarningModal() {
      this.$store.dispatch("gui/hideWarningModal")
    },
    messagesFilteredByUserFrom() {
      return this.getMessages.map((message) => {
        message.isBenef = this.isBeneficiaryUserFrom(message)
        return message
      })
    },
    isBeneficiaryUserFrom(message) {
      return message.user_from.id === this.getCurrentUser.id
    },
    async onInputFile(file) {
      this.isUploadInProgress = true
      this.alreadyUploadedDocument = false
      if (!this.documentAlreadyUploaded(file)) {
        const payload = {
          file,
        }
        try {
          await this.$store.dispatch("document/add", payload)
        } catch (e) {
          console.error("failed to add new document")
        }
      } else {
        this.alreadyUploadedDocument = true
      }
      this.isUploadInProgress = false
    },
    async sendNewMessage() {
      this.isUploadInProgress = true
      let formData = new FormData()
      formData.append("content", this.newMessage.content)
      for (const document of this.messageListDocuments) {
        formData.append("files", document.file, document.file.name)
      }
      try {
        await this.$store.dispatch("messages/create", formData)
      } catch (e) {
        console.error("failed to add new message")
      }
      this.messages = this.messagesFilteredByUserFrom()
      this.isUploadInProgress = false
      this.onCloseNewMessageModal()
    },
    onNewMessageButtonClick() {
      this.showNewMessageModal = true
    },
    onCloseNewMessageModal() {
      this.showNewMessageModal = false
      this.newMessage.content = ""
      this.$store.dispatch("document/clear")
    },
    getLastMessageFromCaarea() {
      return this.messages.find(
        (message) => message.user_from.id !== this.getCurrentUser.id
      )
    },
  },
}
</script>

<style scoped lang="scss">
.div-mail-w {
  background-color: $purple20;
  border-radius: 4px;
  padding: 24px;
}

.chat-form {
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
