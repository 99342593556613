export default {
  fetchLoggedUser: "/users/me/?beneficiary=1",
  userAcceptCookies: "/users/{id}/cookies/",
  userLang: "/users/{id}/lang/",
  contractsByBeneficiaryId: "beneficiaries/{id}/contracts/?is_b2c=true",
  claimsByBeneficiaryId: "beneficiaries/{id}/claims/",
  messagesByBeneficiaryId: "beneficiaries/{id}/messages/",
  newMessage: "messages/",
  messageRead: "messages/{id}/mark-read/",
  claims: "claims/",
  claimDocuments: "claims/{id}/documents/",
  documentsByClaimId: "claims/{id}/documents/",
  downloadDocumentsById: "documents/{id}/download",
}
