import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

// STATES (snake_case)
const state = {
  documents: [],
  download_document: null,
  upload_error: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },
  SET_DOCUMENT(state, document) {
    Vue.set(state.documents, document.id, document)
  },
  SET_DOWNLOAD_DOCUMENT(state, file) {
    state.download_document = file
  },
  SET_UPLOAD_ERROR(state, error) {
    state.upload_error = error
  },
  RESET_UPLOAD_ERROR(state) {
    state.upload_error = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async upload({ commit, state }, payload) {
    commit("RESET_UPLOAD_ERROR")

    let formData = new FormData()
    formData.append("file", payload.file)

    try {
      const response = await HttpService.post(
        UrlService.render("claimDocuments", { id: payload.claimId }),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      commit("SET_DOCUMENT", response)
      return response
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_UPLOAD_ERROR", e.data)
        }
      }
      console.error("upload failure", e)
    }
  },
  async download({ commit }, documentId) {
    try {
      const response = await HttpService.get(
        UrlService.render("downloadDocumentsById", { id: documentId }),
        { responseType: "arraybuffer" }
      )
      commit("SET_DOWNLOAD_DOCUMENT", response)
    } catch (e) {
      console.error("download failure", e)
    }
  },

  async getDocumentsFromClaim({ commit }, claimId) {
    try {
      const response = await HttpService.get(
        UrlService.render("documentsByClaimId", { id: claimId })
      )
      commit("SET_DOCUMENTS", response)
    } catch (e) {
      console.error("getDocuments failure", e)
    }
  },
  resetUploadError({ commit }) {
    commit("RESET_UPLOAD_ERROR")
  },
}

// GETTERS (camelCase)
const getters = {
  getDocuments: (state) => state.documents,
  getDownloadDocument: (state) => state.download_document,
  getUploadError: (state) => state.upload_error,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
