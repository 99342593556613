<template>
  <GreyRectangle class="mr-3">
    <div class="d-flex row pl-3">
      <RectangleInfo
        class="px-4 p-3 pt-4"
        :left-label="$t('contract.subscription')"
        :right-label="$t('contract.deadline')"
        :left-info="format(contract.subscription)"
        :right-info="format(contract.deadline)"
      ></RectangleInfo>
    </div>
    <div class="p-3 d-flex flex-column border-top">
      <h2 class="pl-3">{{ $t("contract.warranty") }}</h2>
      <div class="d-flex flex-row flex-wrap">
        <div
          v-for="product_line in contract.sale.product_lines"
          :key="product_line.id"
          :class="pgStyle"
        >
          <ProductGuaranteed
            :product="product_line.product"
            :config="product_line.config"
            :pg-global-style="pgGlobalStyle"
            :pg-img-style="pgImgStyle"
            :pg-data-cy="pgDataCy"
          ></ProductGuaranteed>
        </div>
      </div>
    </div>
  </GreyRectangle>
</template>

<script>
import ProductGuaranteed from "../products/ProductGuaranteed"
import GreyRectangle from "../base/GreyRectangle"
import RectangleInfo from "../base/RectangleInfo"
import { mapGetters } from "vuex"
import DateService from "@/services/technical/DateService"

export default {
  name: "ContractDetail",
  components: { ProductGuaranteed, GreyRectangle, RectangleInfo },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pgStyle: "",
      pgGlobalStyle: "",
      pgImgStyle: "",
      pgDataCy: "",
    }
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
  },
  mounted() {
    this.setProductGuarantedStyle()
  },
  methods: {
    setProductGuarantedStyle() {
      if (this.contract.sale.product_lines.length === 1) {
        this.pgStyle = "p-3"
        this.pgGlobalStyle = "d-flex flex-row"
        this.pgImgStyle = "w-50 pr-3 pb-3"
        this.pgDataCy = "one-product-line"
      } else {
        this.pgStyle = "p-3 w-50"
        this.pgGlobalStyle = "d-flex flex-column"
        this.pgImgStyle = "w-50 pb-3"
        this.pgDataCy = "more-than-one-product-line"
      }
    },
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
  },
}
</script>

<style lang="scss" scoped></style>
