<template>
  <ModalBootstrap
    v-if="showModal && missingVehicleInfos"
    id="vehicleInfosModal"
    :title="$t('vehicleInfosModal.title')"
    class="criteria"
    modal-size="large"
    data-cy="vehicle-infos-modal"
    @close="onClose"
  >
    <template #body>
      <div class="text-center">
        {{ $t("vehicleInfosModal.text") }}
      </div>
      <div class="vehicle mt-5 mb-3 text-center">
        {{ userVehicle }}
      </div>
      <div class="row flex-column align-items-center">
        <div class="col-lg-6">
          <div class="mb-3">
            <label for="first_release_date">
              {{ $t("vehicleInfosModal.form.first_release_date") }}
            </label>
            <datepicker
              id="first_release_date"
              v-model="vehicleInfos.first_release_date"
              input-class="form-control"
              :language="language"
              :monday-first="isMondayFirst"
            />
          </div>
          <form-row-input
            v-model="vehicleInfos.plate"
            :label="$t('vehicleInfosModal.form.plate')"
            :label-inline="false"
            name="plate"
            placeholder="BL-255-HZ"
          />
          <form-row-input
            v-model="vehicleInfos.km"
            :label="$t('vehicleInfosModal.form.current_mileage')"
            :label-inline="false"
            name="km"
            placeholder="12000"
            :errors="inputErrors"
          />
          <div class="text-center">
            <CallToActionButton
              class="btn-primary"
              :button-label="$t('vehicleInfosModal.form.save')"
              :disabled="disabled"
              @click="saveVehicleInfos"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="legend">
        <span class="material-symbols-outlined">info</span>
        <div>
          {{ $t("vehicleInfosModal.legend") }}
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import { mapGetters } from "vuex"
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import Datepicker from "vuejs-datepicker"
import { en, es, fr, ko } from "vuejs-datepicker/dist/locale"
import CallToActionButton from "@/components/button/CallToActionButton"
import NumberService from "@/services/technical/NumberService"

export default {
  name: "VehicleInfosModal",
  components: {
    ModalBootstrap,
    Datepicker,
    CallToActionButton,
  },
  data() {
    return {
      showModal: true,
      contractId: null,
      vehicle: {},
      vehicleInfos: {
        first_release_date: null,
        plate: "",
        km: "",
      },
      inputErrors: { km: "" },
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("contract", ["getContracts"]),
    ...mapGetters("config", ["getCurrentLang"]),
    missingVehicleInfos() {
      return Object.values(this.vehicle).some((value) => value === "")
    },
    userVehicle() {
      return `${this.vehicle.make} ${this.vehicle.model} - ${this.vehicle.version}`
    },
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
    disabled() {
      return Object.values(this.vehicleInfos).some((value) => value === "")
    },
  },
  watch: {
    $route() {
      this.showModal = true
    },
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "contract/loadByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
    this.contractId = this.getContracts[0].id
    this.vehicle = this.getContracts[0].sale.vehicle
    this.vehicleInfos.first_release_date = this.vehicle.first_release_date
    this.vehicleInfos.plate = this.vehicle.plate
    this.vehicleInfos.km = this.vehicle.km
  },
  methods: {
    onClose() {
      this.showModal = false
    },
    saveVehicleInfos() {
      if (!NumberService.isNumber(this.vehicleInfos.km) || !this.vehicleInfos.km > 0) {
        this.inputErrors.km = this.$t(
          "vehicleInfosModal.inputs_errors.not_a_valid_strictly_positive_number"
        )
      } else {
        this.inputErrors.km = ""
        const payload = {
          contractId: this.contractId,
          vehicleInfos: this.vehicleInfos,
        }
        this.$store.dispatch("contract/saveVehicleInfos", payload)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#vehicleInfosModal {
  ::v-deep {
    .modal-title {
      margin: auto;
    }
    .modal-btn-close {
      position: relative;
      background: transparent;
      border: none;
      color: #56688d;
      font-size: 1.5rem;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #fefefe;
        transform: scale3d(1.3, 1.3, 1.3);
      }
    }
    .vehicle {
      font-size: 17px;
      font-weight: 700;
      line-height: 21px;
    }
    input#first_release_date.form-control[readonly] {
      background: #fff;
    }
    button[disabled] {
      background: #ccc !important;
      cursor: not-allowed;
    }
    .modal-footer {
      background: $purple20;
      .legend {
        display: flex;
        gap: 12px;
      }
    }
    .vdp-datepicker__calendar .cell {
      &.selected {
        background: $primary;
        color: #fff;
      }
      &:hover {
        border-color: $primary;
      }
      &.day-header,
      &.blank {
        &:hover {
          border-color: transparent;
        }
      }
    }
  }
}
</style>
