// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"

const state = {
  contracts: [],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CONTRACTS(state, contracts) {
    state.contracts = contracts
  },
  SET_CONTRACT(state, contract) {
    Vue.set(state.contracts, contract.id, contract)
  },
  SAVE_VEHICLE_INFOS(state, payload) {
    const index = state.contracts.findIndex(
      (contract) => contract.id === payload.contractId
    )
    state.contracts[index].sale.vehicle.first_release_date =
      payload.vehicleInfos.first_release_date
    state.contracts[index].sale.vehicle.plate = payload.vehicleInfos.plate
    state.contracts[index].sale.vehicle.km = payload.vehicleInfos.km
  },
}

// ACTIONS (camelCase)
const actions = {
  async loadByBeneficiaryId({ commit }, beneficiaryId) {
    try {
      const contracts = await HttpService.get(
        UrlService.render("contractsByBeneficiaryId", { id: beneficiaryId })
      )
      commit("SET_CONTRACTS", contracts)
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  saveVehicleInfos({ commit }, payload) {
    commit("SAVE_VEHICLE_INFOS", payload)
  },
}

// GETTERS (camelCase)
const getters = {
  getContractById: (state) => (num) =>
    state.contracts.find((contract) => contract.num === num),
  getContracts: (state) => state.contracts,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
