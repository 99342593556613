<template>
  <div data-cy="claim-declaration" class="container pt-3">
    <div v-if="claimSent">
      <NewClaimModal
        v-if="!hasError"
        data-cy="success-message"
        @close="onClose"
      ></NewClaimModal>
      <ModalWarning
        v-if="hasError"
        :title="errorMessage.title"
        :reason="errorMessage.reason"
        :advice="errorMessage.advice"
        @close="onCloseWarningModal"
      ></ModalWarning>
    </div>
    <div class="row">
      <router-link
        :to="{ name: 'claims' }"
        class="d-flex row text-decoration-none back-claims--w"
      >
        <span class="icon-arrow-left px-2 ml-3"></span>
        <p>{{ $t("claims.back") }}</p>
      </router-link>
      <div class="col-12 mb-3">
        <h1>
          <br />
          <span>{{ $t("newClaim.title") }}</span>
        </h1>
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-7 pr-3 left-div-home-w">
            <div class="rounded purple-div-w">
              <div class="py-3">
                <div class="border-bottom pl-3 mb-2">
                  <h2>{{ $t("newClaim.newClaim") }}</h2>
                  <p class="sf-pro-text">{{ $t("newClaim.explanation") }}</p>
                </div>
                <form>
                  <div class="form-row px-3">
                    <div class="form-group col-md-6">
                      <label for="inputConcernedVehicle" class="required">
                        {{ $t("newClaim.concernedVehicle") }}
                      </label>
                      <multiselect
                        id="inputConcernedVehicle"
                        v-model="form.vehicleSelection"
                        data-cy="claim-vehicle-concerned-select"
                        class="form-multiselect"
                        label="title"
                        track-by="contractId"
                        :options="form.vehicleSelectionOptions"
                        :option-height="200"
                        :show-labels="false"
                        :placeholder="$t('newClaim.yourVehicle')"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <div class="option__desc">
                            <div class="option__background-image">
                              <img
                                class="option__image"
                                :src="`https://res.cloudinary.com/caarea/image/upload/c_scale,w_25/v1624268356/cs/assets/makes_logos/${props.option.logo.toLowerCase()}.png`"
                                :alt="props.option.logo"
                              />
                            </div>
                            <span class="option__title option__title--center">
                              {{ props.option.title }}
                            </span>
                          </div>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc d-flex flex-row">
                            <div class="option__background-image">
                              <img
                                class="option__image"
                                :src="`https://res.cloudinary.com/caarea/image/upload/c_scale,w_25/v1624268356/cs/assets/makes_logos/${props.option.logo.toLowerCase()}.png`"
                                :alt="props.option.logo"
                              />
                            </div>
                            <div class="d-flex flex-column">
                              <span class="option__title">
                                {{ props.option.title }}
                              </span>
                              <p class="mb-0">{{ props.option.plate }}</p>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                    </div>
                    <div class="form-group col-12">
                      <label for="inputDescription" class="required">
                        {{ $t("newClaim.description") }}
                      </label>
                      <textarea
                        id="inputDescription"
                        v-model="form.description"
                        data-cy="claim-description"
                        class="form-control"
                        :placeholder="$t('newClaim.descriptionPlaceholder')"
                        :disabled="hasVehicleSelected"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-row px-3">
                    <div class="form-group col-md-6">
                      <div class="form-group">
                        <label for="inputDate" class="required">
                          {{ $t("newClaim.claimDate") }}
                        </label>
                        <datepicker
                          id="inputDate"
                          v-model="form.date"
                          data-cy="claim-date"
                          input-class="form-control claim-datepicker"
                          :placeholder="$t('newClaim.dateFormat')"
                          :language="language"
                          :monday-first="isMondayFirst"
                          :disabled="hasVehicleSelected"
                          :disabled-dates="{ from: new Date() }"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="form-group">
                        <label for="inputMileage" class="required">
                          {{ $t("newClaim.claimKm") }}
                        </label>
                        <FormInput
                          id="inputMileage"
                          v-model="form.mileage"
                          data-cy="claim-mileage"
                          name="inputMileage"
                          type="number"
                          :min="minMileage"
                          :disabled="hasVehicleSelected"
                        ></FormInput>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <button
                        type="button"
                        data-cy="claim-send-declaration"
                        :disabled="isDisabled"
                        class="btn btn-primary rounded-pill"
                        data-toggle="modal"
                        data-target="#newClaimModal"
                        @click.prevent="onSubmitButton"
                      >
                        {{ $t("newClaim.sendDeclaration") }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-5">
            <ClaimDocuments @input-file="onInputFile">
              <div
                v-if="hasNoDocument"
                class="d-flex flex-row justify-content-center align-items-center pt-3"
                data-cy="empty-documents"
              >
                <div class="d-flex flex-column align-items-center">
                  <span
                    class="
                      icon-file-empty
                      p-3
                      d-flex
                      justify-content-center
                      align-items-center
                      bg-white
                      br-8
                      mr-2
                      w-25
                    "
                  ></span>
                  <p class="mb-4 pt-3">{{ $t("claims.document.waiting_for_doc") }}</p>
                </div>
              </div>
              <div
                v-for="uploadedFile in files"
                v-else
                :key="uploadedFile.name"
                class="d-flex flex-row px-2 p-3 claim-document-info--w"
                data-cy="documents-list"
              >
                <span
                  class="
                    icon-file-empty
                    p-3
                    d-flex
                    justify-content-center
                    align-items-center
                    bg-white
                    br-8
                    mr-2
                  "
                ></span>
                <div class="d-flex flex-column over">
                  <p class="p-1 mb-0">
                    {{ todayDate }}
                  </p>
                  <p class="pl-1 mb-0">
                    {{ uploadedFile.name }}
                  </p>
                </div>
              </div>
            </ClaimDocuments>
            <InformativDiv
              icon="icon-bubble-question"
              :title-div="$t(`claims.faq_title`)"
              :details="faqTextDetails"
              link-button
            ></InformativDiv>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { en, es, fr, ko } from "vuejs-datepicker/dist/locale"
import InformativDiv from "../layouts/components/InformativDiv"
import NewClaimModal from "../components/claims/NewClaimModal"
import ClaimDocuments from "@/components/claims/ClaimDocuments"
import Multiselect from "vue-multiselect"
import StringService from "@/services/technical/StringService"
import ModalWarning from "@/components/modal/ModalWarning"
import { mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "NewClaim",
  components: {
    Datepicker,
    InformativDiv,
    NewClaimModal,
    ClaimDocuments,
    Multiselect,
    ModalWarning,
  },
  data() {
    return {
      claim: {},
      isLoading: true,
      errorMessage: null,
      claimSent: false,
      hasError: false,
      error: {
        title: "",
        reason: "",
      },
      files: [],
      form: {
        description: "",
        date: "",
        mileage: "",
        vehicleSelection: "",
        vehicleSelectionOptions: [],
      },
      faqTextDetails: this.$t(`claims.faq_text`),
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("config", ["getCurrentLang"]),
    ...mapGetters("contract", ["getContracts"]),
    isDisabled() {
      return (
        !this.form.description ||
        !this.form.date ||
        !this.form.vehicleSelection ||
        !this.form.mileage
      )
    },
    hasVehicleSelected() {
      return !(this.form.vehicleSelection instanceof Object)
    },
    minMileage() {
      return this.form.vehicleSelection.km || 0
    },
    hasNoDocument() {
      return this.files.length === 0
    },
    todayDate() {
      return moment(Date.now()).format("YYYY-MM-DD")
    },
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "contract/loadByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.form.vehicleSelectionOptions = this.getContracts.map((contract) => ({
          contractId: contract.id,
          title:
            StringService.title(contract.sale.vehicle.make) +
            " " +
            contract.sale.vehicle.model,
          plate: contract.sale.vehicle.plate,
          logo: contract.sale.vehicle.make,
          km: contract.sale.vehicle.km,
        }))
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
  },
  methods: {
    onClose() {
      this.$router.push({ name: "claims" })
    },
    onCloseWarningModal() {
      this.claimSent = false
      this.hasError = false
    },
    async onInputFile(file) {
      this.files.push(file)
    },
    async onSubmitButton() {
      let formData = new FormData()
      formData.append("incident_date", moment(this.form.date).format("YYYY-MM-DD"))
      formData.append("description", this.form.description)
      formData.append("contract_id", this.form.vehicleSelection.contractId)
      formData.append("occurrence_mileage", this.form.mileage)
      for (let file of this.files) {
        formData.append("files", file)
      }
      try {
        await this.$store.dispatch("claims/createClaim", formData)
      } catch (e) {
        console.error("failed to create new claim")
        this.hasError = true
        this.errorMessage = {
          reason: `${this.$t("newClaim.errorModal.reason.field")} ${Object.keys(
            e.data
          ).toString()}`,
          advice: Object.values(e.data).toString(),
        }
        this.formatError(e)
      } finally {
        this.claimSent = true
      }
    },
    formatError(exception) {
      if (exception.data.hasOwnProperty("files")) {
        this.files = []
        this.errorMessage = {
          title: this.$t("newClaim.errorModal.title.document"),
          reason: `${this.$t("newClaim.errorModal.reason.file")} ${
            parseInt(Object.keys(exception.data.files)[0]) + 1
          }`,
          advice: Object.values(exception.data.files)[0],
        }
      } else {
        this.errorMessage = {
          title: this.$t("newClaim.errorModal.title.general"),
          reason: `${this.$t("newClaim.errorModal.reason.field")} ${Object.keys(
            exception.data
          ).toString()}`,
          advice: Object.values(exception.data).toString(),
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.back-claims--w {
  color: #fff;
  opacity: 0.7;
  width: 10%;
  &:hover {
    color: gray;
  }
}
.claim-document-info--w:not(:last-child) {
  border-bottom: 1px solid lightgray;
  padding: 20px 0;
}

.option {
  &__background-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin: 2px 8px 0 0;
    padding: 0 5px;
    background-color: white;
  }
  &__desc {
    display: flex;
  }
  &__title--center {
    margin-top: 10px;
  }
}

.form-multiselect:hover {
  cursor: pointer;
}

.required:after {
  content: "*";
  color: red;
}

::v-deep .claim-datepicker {
  height: 44px !important;

  &:not([disabled]) {
    background-color: #fff;
  }
}
</style>
