<template>
  <router-link :to="{ name: 'claimsDetail', params: { claimNum: claim.num } }">
    <div class="p-3 bg-2-1-2 border-top">
      <div class="row">
        <div class="col-8 d-flex flex-column">
          <h3 :class="getStatusDetails(claim.current_status).color">
            {{ $t("claims.event_number") }}{{ claim.num }}
          </h3>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column mr-3">
              <p class="mb-0">{{ $t("claims.date") }}</p>
              <p class="font-weight-bold">{{ format(claim.incident_date) }}</p>
            </div>
            <div class="d-flex flex-column">
              <p class="mb-0">{{ $t("claims.vehicle") }}</p>
              <p class="font-weight-bold">{{ getVehicleMake }}</p>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex flex-row justify-content-around align-items-center">
          <div class="bg-white py-1 px-3 br-100">
            <p
              class="mb-0 d-flex"
              :class="getStatusDetails(claim.current_status).color"
            >
              <span :class="getStatusDetails(claim.current_status).icon"></span>
              <span class="ml-1">
                {{ getStatusDetails(claim.current_status).text }}
              </span>
            </p>
          </div>
          <span class="icon-chevron-right-circle align-middle"></span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import StringService from "@/services/technical/StringService"
import ClaimsMixin from "../mixins/ClaimsMixin"
import DateService from "@/services/technical/DateService"
import { mapGetters } from "vuex"

export default {
  name: "ClaimsPreview",
  mixins: [ClaimsMixin],
  props: {
    claim: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),

    getVehicleMake() {
      return (
        StringService.title(this.claim.contract.sale.vehicle.make) +
        " " +
        this.claim.contract.sale.vehicle.model
      )
    },
  },
  methods: {
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  &:hover {
    text-decoration: none;
  }
}
h3 {
  margin-bottom: 0;
}
p {
  font-size: 15px;
  color: $blue80;
}
span[class^="icon"] {
  font-size: 18px;
}
.font-weight-bold {
  color: $blue100 !important;
}
</style>
