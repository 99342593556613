<template>
  <div data-cy="claim-list" class="container pt-5 vh-100">
    <div v-if="isLoading" class="mt-5">
      <spinner></spinner>
    </div>
    <div v-else class="row">
      <div class="col-6 mb-3">
        <h1>
          <br />
          <span>{{ $t("claims.title") }}</span>
        </h1>
      </div>
      <div class="col-6 d-flex flex-row-reverse pt-3">
        <br />
        <router-link
          :to="{
            name: 'newClaim',
          }"
          class="text-decoration-none"
        >
          <TopButtonWithIcon icon="icon-warning" :label="$t('newClaim.buttonLabel')" />
        </router-link>
      </div>
      <div class="col-12 mb-3 vh-100">
        <div class="row">
          <div class="col-7 pr-3 left-div-home-w">
            <div class="rounded purple-div-w">
              <div class="row py-3 pl-3 pr-3">
                <div class="col-6">
                  <h2 class="mb-0">{{ $t("claims.declaration") }}</h2>
                  <p class="sf-pro-text">{{ $t("claims.procedure") }}</p>
                </div>
                <FormRowSelect
                  class="col-6"
                  :label-class="['text-right', 'col-sm-5']"
                  :label-inline="false"
                  :control-class="['col-sm-7']"
                  name="claims_selection"
                  :selected-option.sync="claimSelection"
                  label-select-attr="label"
                  :select-options="claimSelectionOptions"
                  :errors="{}"
                  @update:selected-option="onUpdateClaimSelection"
                ></FormRowSelect>
              </div>
              <ClaimsPreview
                v-for="(claim, index) in selectedClaims"
                :key="index"
                :data-cy="'status-' + claim.current_status"
                :claim="claim"
              ></ClaimsPreview>
            </div>
          </div>
          <div class="col-5">
            <InformativDiv
              icon="icon-bubble-alert"
              :details="faqTextDetails"
              link-button
            ></InformativDiv>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClaimsPreview from "../components/claims/ClaimsPreview"
import InformativDiv from "../layouts/components/InformativDiv"
import TopButtonWithIcon from "../components/button/TopButtonWithIcon"
import { mapGetters } from "vuex"
export default {
  name: "Claims",
  components: { ClaimsPreview, InformativDiv, TopButtonWithIcon },
  data() {
    return {
      faqTextDetails: this.$t(`claims.faq_text`),
      claimSelection: "lastEvents",
      claimSelectionOptions: {
        lastEvents: this.$t(`claims.state.last_events`),
        pendingEvents: this.$t(`claims.state.pending_events`),
        closedEvents: this.$t(`claims.state.closed_events`),
      },
      selectedClaims: [],
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("claims", ["getClaims"]),
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "claims/loadClaimsByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.selectedClaims = this.getClaims
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
  },
  methods: {
    selectByPending() {
      return this.getClaims.filter(
        (claim) =>
          claim.current_status === "ACCEPTED" ||
          claim.current_status === "PENDING" ||
          claim.current_status === "IN_PROGRESS"
      )
    },
    selectByClosed() {
      return this.getClaims.filter(
        (claim) => claim.current_status === "DONE" || claim.current_status === "REFUSED"
      )
    },
    onUpdateClaimSelection() {
      if (this.claimSelection === "pendingEvents") {
        this.selectedClaims = this.selectByPending()
      } else if (this.claimSelection === "closedEvents") {
        this.selectedClaims = this.selectByClosed()
      } else {
        this.selectedClaims = this.getClaims
      }
    },
  },
}
</script>

<style scoped>
p {
  font-size: 15px;
}
</style>
