<template>
  <div class="d-flex row">
    <div class="px-3 border-right">
      <span class="red-had-text font-weight-light">{{ leftLabel }}</span>
      <br />
      <span v-if="leftInfo" class="font-weight-bold">{{ leftInfo }}</span>
      <span v-else class="font-weight-bold">#NA</span>
    </div>
    <div class="px-3">
      <span class="red-had-text font-weight-light">{{ rightLabel }}</span>
      <br />
      <span v-if="rightInfo" class="font-weight-bold">{{ rightInfo }}</span>
      <span v-else class="font-weight-bold">#NA</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractInfo",
  props: {
    leftLabel: {
      type: String,
      required: true,
    },
    leftInfo: {
      type: String,
    },
    rightLabel: {
      type: String,
      required: true,
    },
    rightInfo: {
      type: String,
    },
  },
}
</script>

<style scoped></style>
