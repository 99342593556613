<template>
  <div class="container pt-5">
    <div v-if="isLoading" class="mt-5">
      <spinner></spinner>
    </div>
    <div v-else class="row">
      <div class="col-12 mb-3">
        <h1 data-cy="welcome">
          {{ $t("home.hello") }}
          <br />
          <span data-cy="full_name">{{ fullname }}</span>
        </h1>
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-7 pr-3 left-div-home-w">
            <div
              v-if="hasNoContract"
              data-cy="no_contract_div"
              class="rounded guaranties-w p-3"
            >
              <h3>{{ $t("home.no_contract") }}</h3>
            </div>
            <div v-else class="rounded guaranties-w">
              <div class="p-3">
                <h2 class="mb-0">{{ $t("home.guarantees") }}</h2>
                <p class="sf-pro-text">{{ $t("home.contract_details") }}</p>
              </div>
              <ContractPreview
                v-for="contract in getContracts"
                :key="contract.id"
                data-cy="contract_div"
                :logo="contract.sale.vehicle.make"
                :make="getMake(contract)"
                :immat="contract.sale.vehicle.plate"
                :contract="contract.num"
              ></ContractPreview>
            </div>
          </div>
          <div class="col-5">
            <InformativDiv
              icon="icon-mailbox-empty"
              :title-div="$t('home.mail')"
              :details="nonReadMessagesNumber + ' ' + $t('home.new_message')"
              link-button="mails"
            ></InformativDiv>
            <InformativDiv
              icon="icon-bubble-question"
              :title-div="$t('home.faq')"
              details="lorem"
              link-button="faq"
            ></InformativDiv>
          </div>
        </div>
      </div>
    </div>
    <transition appear name="slide">
      <div
        v-if="!getCurrentUser.profile.accepted_cookies"
        id="overlay"
        data-cy="cookies-overlay"
        class="text-white fixed-bottom"
      >
        <h1 class="pl-4 pt-3" data-cy="cookies-title">
          {{ $t("home.cookies.title") }}
        </h1>
        <div class="pt-4 px-5">
          <p class="mr-3" data-cy="cookies-description">
            {{ $t("home.cookies.description") }}
          </p>
          <div class="row">
            <div class="col-10">
              <p data-cy="cookies-description-2">
                {{ $t("home.cookies.description2") }}
              </p>
            </div>
            <div class="col-2 pt-4 pb-4 pr-5 text-right">
              <button
                type="button"
                class="btn-cookie-accept"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                data-cy="accept-cookies"
                @click="onClickAcceptCookies"
              >
                {{ $t("button.accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ContractPreview from "../components/contracts/ContractPreview"
import InformativDiv from "../layouts/components/InformativDiv"
import StringService from "../services/technical/StringService"

export default {
  name: "Home",
  components: { InformativDiv, ContractPreview },
  data: function () {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser", "getUserLang"]),
    ...mapGetters("contract", ["getContracts"]),
    ...mapGetters("messages", ["getMessages"]),
    fullname() {
      if (this.getCurrentUser.first_name || this.getCurrentUser.last_name) {
        return this.getCurrentUser.first_name + " " + this.getCurrentUser.last_name
      }
      return this.getCurrentUser.username
    },
    hasNoContract() {
      return this.getContracts.length === 0
    },
    nonReadMessagesNumber() {
      return this.getMessages.filter((message) => message.read_date === null).length
    },
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "contract/loadByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }

      try {
        await this.$store.dispatch(
          "messages/loadMessagesByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.messages = this.messagesFilteredByUserFrom()
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
      await this.$store.dispatch("config/setUserLang")
      const to = this.$router.resolve({ params: { lang: this.getUserLang } })
      if (this.$route.params.lang !== to.location.params.lang) {
        await this.$router.push(to.location)
      }
    }
  },
  methods: {
    async onClickAcceptCookies() {
      await this.$store.dispatch("auth/acceptCookies", this.getCurrentUser.id)
    },
    getMake(contract) {
      return StringService.title(
        contract.sale.vehicle.make + " " + contract.sale.vehicle.model
      )
    },
    messagesFilteredByUserFrom() {
      return this.getMessages.map((message) => {
        message.isBenef = this.isBeneficiaryUserFrom(message)
        return message
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 24px;
  color: #fff;
  span {
    font-weight: bold;
    font-size: 32px;
  }
}

p {
  font-size: 15px;
}
#overlay {
  background-color: rgba(106, 102, 94, 0.7);
  z-index: 1031;
}
.guaranties-w {
  background-color: $purple20;
  p {
    color: $blue60;
  }
}
.left-div-home-w {
  h2,
  h3 {
    color: $blue100;
  }
}
</style>
