<template>
  <ModalBootstrap
    :title="$t('newClaim.modalTitle')"
    class="criteria"
    modal-size="large"
    data-cy="warning-modal"
    @close="onClose"
  >
    <template #body>
      <div class="modal-body">
        <div class="text-center">
          <span class="icon-document newClaimModalGreen"></span>
          <h1 class="newClaimModalGreen p-3">{{ $t("newClaim.modalTitle") }}</h1>
          <p>
            {{ $t("newClaim.modalDescription") }}
          </p>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "NewClaimModal",
  components: { ModalBootstrap },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.newClaimModalGreen {
  color: $success100;
}
</style>
