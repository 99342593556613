<template>
  <GreyRectangle>
    <div class="d-flex row p-3 pt-4">
      <div class="px-3">
        <img
          :src="`https://res.cloudinary.com/caarea/image/upload/c_scale,w_41,r_10/v1624268356/cs/assets/makes_logos/${logo.toLowerCase()}.png`"
          :alt="logo"
        />
      </div>
      <div>
        <h2 class="mb-0">{{ vehicle.make }} {{ vehicle.model }}</h2>
        <p class="mb-0">{{ vehicle.plate }}</p>
      </div>
    </div>
    <RectangleInfo
      class="p-3"
      :left-label="$t('contract.model')"
      :right-label="$t('contract.release_date')"
      :left-info="vehicle.model"
      :right-info="format(vehicle.first_release_date)"
    ></RectangleInfo>
    <div class="pt-3 pl-3 border-top">
      <h2>{{ $t("contract.maintenance") }}</h2>
    </div>
    <RectangleInfo
      class="p-3"
      :left-label="$t('contract.last_maintenance')"
      :right-label="$t('contract.kilometer')"
      :left-info="format(vehicle.last_maintenance)"
      :right-info="vehicle.km + ' km'"
    ></RectangleInfo>
    <div class="border-bottom"></div>
  </GreyRectangle>
</template>

<script>
import GreyRectangle from "../base/GreyRectangle"
import RectangleInfo from "../base/RectangleInfo"
import DateService from "@/services/technical/DateService"
import { mapGetters } from "vuex"

export default {
  name: "VehicleDetail",
  components: { GreyRectangle, RectangleInfo },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    logo: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
  },
  methods: {
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
  },
}
</script>

<style scoped></style>
