import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import i18n from "../i18n"

// STATES (snake_case)
const state = {
  messages: [],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_MESSAGES(state, messages) {
    state.messages = messages
  },
  SET_MESSAGE(state, message) {
    state.messages.unshift(message)
  },
}

// ACTIONS (camelCase)
const actions = {
  async loadMessagesByBeneficiaryId({ commit }, beneficiaryId) {
    try {
      const messages = await HttpService.get(
        UrlService.render("messagesByBeneficiaryId", { id: beneficiaryId })
      )
      commit("SET_MESSAGES", messages)
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async create({ commit, dispatch }, formData) {
    try {
      const message = await HttpService.post(
        UrlService.render("newMessage"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      commit("SET_MESSAGE", message)
    } catch (e) {
      console.error("message creation failed: ", e)
      if (e && e.status && e.status < 500) {
        const reason =
          e.status === 400 || e.status === 404
            ? e.data
            : e.status === 422
            ? _getSendingMessageFailure422Reason(e)
            : i18n.t("mails.warning_modal.default_reason")
        const payload = {
          title: i18n.t("mails.warning_modal.title"),
          reason: reason,
          advice: i18n.t("mails.warning_modal.advice"),
        }
        await dispatch("gui/showWarningModal", payload, {
          root: true,
        })
      } else {
        throw e
      }
    }
  },
  async update({ commit }, messageId) {
    try {
      const message = await HttpService.post(
        UrlService.render("messageRead", { id: messageId })
      )
      commit("SET_MESSAGE", message)
    } catch (e) {
      console.error("message updated failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getMessages: (state) => state.messages,
}

function _getSendingMessageFailure422Reason(exception) {
  const reason = Object.entries(exception.data).reduce((acc, [k, v]) => {
    acc += `\n${k}: ${v}`
    return acc
  }, "")
  console.log({ reason })
  return reason
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
