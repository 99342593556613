<template>
  <button type="button" :disabled="isDisabled" @click="$emit('click', $event)">
    <span :class="icon"></span>
    <span>{{ buttonLabel }}</span>
  </button>
</template>

<script>
export default {
  name: "CallToActionButton",
  props: {
    icon: { type: String },
    buttonLabel: { type: String },
    isDisabled: { type: Boolean, default: false },
  },
}
</script>

<style scoped lang="scss">
span {
  font-size: 16px;
  &[class^="icon"] {
    font-size: 16px;
  }
  &:first-child {
    margin-right: 5px;
  }
}
</style>
