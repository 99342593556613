import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("claimDocument", ["getDownloadDocument"]),
  },
  methods: {
    async onDocumentInfoClick(claimDocument) {
      await this.$store.dispatch("claimDocument/download", claimDocument.id)
      const blob = new Blob([this.getDownloadDocument])
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = claimDocument.filename
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
}
