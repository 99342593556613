<template>
  <button type="button" class="btn-event--w">
    <span :class="icon + ' px-2'"></span>
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "TopButtonWithIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-event--w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border-color: $blue100;
  span {
    color: $blue100;
  }
}
</style>
