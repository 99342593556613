<template>
  <router-link
    :to="{
      name: 'contract',
      params: { contractId: contract },
    }"
    class="text-decoration-none"
  >
    <div class="py-3 px-2 d-flex justify-content-between border-top">
      <div class="d-flex flex-row justify-content-start">
        <div class="p-2">
          <img
            :src="`https://res.cloudinary.com/caarea/image/upload/c_scale,w_41/v1624268356/cs/assets/makes_logos/${logo.toLowerCase()}.png`"
            :alt="make"
          />
        </div>
        <div class="d-flex flex-column p-2">
          <h3>{{ make }}</h3>
          <p class="red-had-text">
            {{ immat }} - {{ $t("home.contract") }} {{ $t("home.number") }}
            {{ contract }}
          </p>
        </div>
      </div>
      <div>
        <span class="icon-chevron-right-circle align-middle"></span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ContractPreview",
  props: {
    logo: { type: String, required: true },
    make: { type: String, required: true },
    immat: { type: String, default: "no licence plate" },
    contract: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
a {
  &:hover {
    text-decoration: none;
  }
}
h3 {
  margin-bottom: 0;
}
p {
  font-size: 15px;
  color: $blue80;
}
</style>
