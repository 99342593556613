import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "@/layouts/Layout"
import NoLayout from "@/layouts/LayoutAuth"
import guards from "./guards"
import Page403 from "@/views/errors/Page403"
import Page404 from "@/views/errors/Page404"
import Page500 from "../views/errors/Page500"
import Home from "@/views/Home"
import Claims from "@/views/Claims"
import Mails from "@/views/Mails"
import ClaimDetail from "@/views/ClaimDetail"
import Contract from "@/views/Contract"
import Faq from "@/views/Faq"
import NewClaim from "@/views/NewClaim"
import Cypress from "@/views/Cypress"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        beforeEnter: guards.requireAuth,
        redirect: { name: "home" },
        component: Layout,
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            meta: {
              fullLayout: true,
            },
          },
          {
            path: "contracts/:contractId",
            name: "contract",
            component: Contract,
            meta: {
              fullLayout: true,
            },
          },
          {
            path: "claims",
            name: "claims",
            component: Claims,
          },
          {
            path: "mails",
            name: "mails",
            component: Mails,
          },
          {
            path: "faq",
            name: "faq",
            component: Faq,
          },
          {
            path: "claims/new",
            name: "newClaim",
            component: NewClaim,
          },
          {
            path: "claims/:claimNum",
            name: "claimsDetail",
            component: ClaimDetail,
          },
        ],
      },
      {
        path: "",
        redirect: { name: "home" },
        name: "publicZone",
        component: NoLayout,
        children: [
          {
            beforeEnter: guards.logout,
            path: "logout",
            name: "logout",
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoLayout,
        children: [
          {
            path: "403",
            name: "403",
            component: Page403,
          },
          {
            path: "404/:message?",
            name: "404",
            component: Page404,
            props: true,
          },
          {
            path: "500",
            name: "500",
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: Cypress,
  })
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
