export default {
  methods: {
    getStatusDetails(status) {
      switch (status) {
        case "IN_PROGRESS":
          return {
            icon: "icon-self-timer",
            text: this.$t(`claims.state.in_progress`),
            color: "text-danger",
          }
        case "ACCEPTED":
          return {
            icon: "icon-check",
            text: this.$t(`claims.state.accepted`),
            color: "text-success",
          }
        case "PENDING":
          return {
            icon: "icon-timer2",
            text: this.$t(`claims.state.pending`),
            color: "text-danger",
          }
        case "DONE":
          return {
            icon: "icon-cross",
            text: this.$t(`claims.state.done`),
            color: "text-secondary",
          }
        case "REFUSED":
          return {
            icon: "icon-cross-circle",
            text: this.$t(`claims.state.refused`),
            color: "text-danger",
          }
        default:
          return {
            icon: "icon-cross-circle",
            text: this.$t(`claims.state.empty`),
            color: "text-danger",
          }
      }
    },
  },
}
