import local_storage_names from "@/conf/local_storage_names"

// STATES (snake_case)
const state = {
  documents: localStorage.getItem(local_storage_names.document.message) || [],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  ADD(state, document) {
    state.documents.push(document)
    localStorage.setItem(local_storage_names.document.message, state.documents)
  },
  DEL(state, basketId) {
    state.documents = state.documents.filter((i) => i._userId !== userId)
    localStorage.setItem(local_storage_names.document.message, state.documents)
  },
  CLEAR(state) {
    state.documents = []
    localStorage.setItem(local_storage_names.document.message, state.documents)
  },
}

// ACTIONS (camelCase)
const actions = {
  add({ commit }, payload) {
    // if (payload.file)
    commit("ADD", payload)
  },
  del({ commit }, userId) {
    commit("DEL", userId)
  },
  clear({ commit }) {
    commit("CLEAR")
  },
}

// GETTERS (camelCase)
const getters = {
  messageHasDocument: (state) => (userId) =>
    state.items.find((i) => i._userId === userId),
  messageNbDocuments: (state) => state.documents.length,
  messageIsEmpty: (state) => state.documents.length < 1,
  messageListDocuments: (state) => state.documents,
  documentAlreadyUploaded: (state) => (file) => {
    let document_in_message = state.documents.find(
      (document) => document.file === file.name
    )
    if (document_in_message === undefined) return false
    return true
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
