<template>
  <div v-if="isLoading">
    <spinner></spinner>
  </div>
  <div v-else class="container vh-100 pt-3">
    <ModalWarning
      v-if="getUploadError"
      :title="$t('claims.document.errorModal.title')"
      :reason="getUploadError"
      @close="onCloseModal"
    ></ModalWarning>
    <div class="row">
      <div class="col-12 mb-1 bg-1 px-0">
        <router-link
          :to="{ name: 'claims' }"
          class="d-flex row text-decoration-none mb-3 back-claims--w"
        >
          <span class="icon-arrow-left px-2 ml-2"></span>
          <p>{{ $t("claims.back") }}</p>
        </router-link>

        <div class="d-flex flex-row justify-content-between mb-4 px-0">
          <div class="claims-title--w">
            <h1 class="gilroy-text">
              {{ $t("claims.event_number") }}
              {{ claim.num }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 h-100">
      <div class="row">
        <div class="col-7 px-0 h-75" data-cy="claim-detail">
          <div
            v-if="getWarningMessage"
            class="rounded mr-3 px-2 p-2 mb-2 event-alert--w"
          >
            <span class="icon-warning pr-1"></span>
            <!-- todo: remplacer de façon dynamique quand le projet claims sera développé-->
            <h2 class="mb-0 pt-1 red-had-text">
              Message d’alerte au sujet de l’évènement
            </h2>
          </div>
          <GreyRectangle class="mr-3">
            <div class="d-flex row">
              <div class="p-2 px-4 mt-3 mb-3 ml-3 border-right">
                <span class="red-had-text font-weight-light">
                  {{ $t("claims.date") }}
                </span>
                <br />
                <span class="font-weight-bold">{{ format(claim.incident_date) }}</span>
              </div>
              <div class="p-2 px-4 mt-3 mb-3 border-right">
                <span class="red-had-text font-weight-light">
                  {{ $t("contract.contract") }}
                </span>
                <br />
                <span class="font-weight-bold">
                  {{ claim.contract.num }}
                </span>
              </div>
              <div class="p-2 px-4 mt-3 mb-3">
                <span class="red-had-text font-weight-light">
                  {{ $t("claims.state.label") }}
                </span>
                <br />
                <p
                  class="mb-0 d-flex"
                  :class="getStatusDetails(claim.current_status).color"
                >
                  <span :class="getStatusDetails(claim.current_status).icon"></span>
                  <span class="font-weight-bold pl-1">
                    {{ getStatusDetails(claim.current_status).text }}
                  </span>
                </p>
              </div>
            </div>
          </GreyRectangle>
          <GreyRectangle class="border-top mr-3">
            <div class="d-flex row ml-1">
              <div class="mr-4">
                <div class="d-flex row p-3 pt-4">
                  <div class="px-3">
                    <img
                      :src="`https://res.cloudinary.com/caarea/image/upload/c_scale,w_41,r_10/v1624268356/cs/assets/makes_logos/${logo.toLowerCase()}.png`"
                      :alt="logo"
                    />
                  </div>
                  <div>
                    <h2 class="mb-0">
                      {{ claim.contract.sale.vehicle.make }}
                      {{ claim.contract.sale.vehicle.model }}
                    </h2>
                    <p class="mb-0">{{ claim.contract.sale.vehicle.plate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 ml-1">
              <p class="claim-description-label">{{ $t("claims.description") }}</p>
              <p class="claim-description-text font-weight-bold">
                {{ claim.description }}
              </p>
            </div>
          </GreyRectangle>
        </div>
        <div class="col-5 px-0">
          <spinner v-if="isUploadInProgress"></spinner>
          <ClaimDocuments v-else @input-file="onInputFile">
            <div
              v-if="hasNoDocument"
              class="d-flex flex-row justify-content-center align-items-center pt-3"
            >
              <div class="d-flex flex-column align-items-center">
                <span
                  class="
                    icon-file-empty
                    p-3
                    d-flex
                    justify-content-center
                    align-items-center
                    bg-white
                    br-8
                    mr-2
                    w-25
                  "
                ></span>
                <p class="mb-4 pt-3">{{ $t("claims.document.waiting_for_doc") }}</p>
              </div>
            </div>
            <div
              v-for="claimDocument in getDocuments"
              v-else
              :key="claimDocument.id"
              class="d-flex flex-row px-2 p-3 claim-document-info--w"
              @click="onDocumentInfoClick(claimDocument)"
            >
              <span
                class="
                  icon-file-empty
                  p-3
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  br-8
                  mr-2
                "
              ></span>
              <div class="d-flex flex-column">
                <p class="p-1 mb-0">{{ claimDocument.created_at }}</p>
                <p class="pl-1 mb-0 mouse-pointer link">
                  {{ claimDocument.filename }}
                </p>
              </div>
            </div>
          </ClaimDocuments>
          <InformativDiv
            icon="icon-mailbox-empty"
            :title-div="$t('claims.text_message')"
            :details="$t('claims.ask_question')"
            data-cy="more-info"
          ></InformativDiv>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InformativDiv from "../layouts/components/InformativDiv"
import GreyRectangle from "../components/base/GreyRectangle"
import ClaimsMixin from "../components/mixins/ClaimsMixin"
import DownloadDocumentMixin from "@/components/mixins/DownloadDocumentMixin"
import ClaimDocuments from "@/components/claims/ClaimDocuments"
import ModalWarning from "@/components/modal/ModalWarning"
import { mapGetters } from "vuex"
import DateService from "@/services/technical/DateService"

export default {
  name: "ClaimsDetail",
  components: { InformativDiv, GreyRectangle, ClaimDocuments, ModalWarning },
  mixins: [ClaimsMixin, DownloadDocumentMixin],
  data() {
    return {
      claim: {},
      isLoading: true,
      isUploadInProgress: false,
      //Todo: Voir commment ça marche quand l'api sera appelé
      warningMessage: false,
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("config", ["getCurrentLang"]),
    ...mapGetters("claims", ["getClaims", "getClaimByNum"]),
    ...mapGetters("claimDocument", ["getDocuments", "getUploadError"]),
    logo() {
      return this.claim.contract.sale.vehicle.make
    },
    //Todo: Voir commment ça marche quand l'api sera appelé
    getWarningMessage() {
      return this.warningMessage
    },
    hasNoDocument() {
      return this.getDocuments.length === 0
    },
  },
  async created() {
    if (this.getCurrentUser) {
      try {
        await this.$store.dispatch(
          "claims/loadClaimsByBeneficiaryId",
          this.getCurrentUser.beneficiary_id
        )
        this.claim = this.getClaimByNum(this.$route.params.claimNum)
        await this.$store.dispatch("claimDocument/getDocumentsFromClaim", this.claim.id)
        this.isLoading = false
      } catch (e) {
        console.error("failed: ", e)
      }
    }
  },
  methods: {
    async onInputFile(file) {
      this.isUploadInProgress = true
      const payload = {
        file: file,
        claimId: this.claim.id,
      }
      await this.$store.dispatch("claimDocument/upload", payload)
      await this.$store.dispatch("claimDocument/getDocumentsFromClaim", this.claim.id)
      this.isUploadInProgress = false
    },
    onCloseModal() {
      this.$store.dispatch("claimDocument/resetUploadError")
    },
    format(date) {
      return DateService.format(date, "DD MMMM YYYY", this.getCurrentLang)
    },
  },
}
</script>

<style lang="scss" scoped>
.back-claims--w {
  color: #fff;
  opacity: 0.7;
  width: 10%;
  &:hover {
    color: gray;
  }
}
.claims-title--w {
  h1 {
    margin-bottom: 0;
  }
  p {
    color: #fff;
    opacity: 0.7;
    font-size: 20px;
    margin-bottom: 0;
  }
}
.event-alert--w {
  display: flex;
  align-items: center;
  background-color: $warning20;
  color: $warning100;
  border-left: 0.5rem solid $warning100;
}
.amount--w {
  font-weight: bold;
  color: $blue100;
}
.claim-document-info--w {
  cursor: pointer;
}
.claim-document-info--w:not(:last-child) {
  border-bottom: 1px solid lightgray;
  padding: 20px 0;
}

.claim-description-label {
  color: $blue80;
}

.claim-description-text {
  color: $blue100;
}
</style>
