import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import config from "@/store/config"
import contract from "@/store/contract"
import claims from "@/store/claims"
import messages from "@/store/messages"
import document from "@/store/document"
import claimDocument from "@/store/claim_document"
import gui from "@/store/gui"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    config,
    contract,
    claims,
    messages,
    document,
    claimDocument,
    gui,
  },
})
