<template>
  <spinner v-if="!isLoggedIn"></spinner>
  <div v-else class="mainlayout">
    <VehicleInfosModal />
    <div class="mainlayout-inside background-caarea-image">
      <top-bar class="topbar--w"></top-bar>
      <div class="mainlayout-content container-fluid frieze px-5 pt-4">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <LayoutFooter></LayoutFooter>
      <div v-if="$router.currentRoute.name !== 'home'" class="mainlayout-footer"></div>
    </div>
  </div>
</template>

<script>
import VehicleInfosModal from "@/components/VehicleInfosModal"
import TopBar from "@/layouts/components/TopBar"
import LayoutFooter from "./components/LayoutFooter"
import { mapGetters } from "vuex"

export default {
  name: "Layout",
  components: { LayoutFooter, TopBar, VehicleInfosModal },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },
}
</script>

<style lang="scss" scoped>
.background-caarea-image {
  height: 320px;
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1624024403/cs/assets/bg-home-1_ercphv.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-caarea-white {
  height: 320px;
  background-image: none;
  background-color: #fff;
}
.topbar--w {
  height: 70px;
  background-color: #fff;
}
</style>
