<template>
  <GreyRectangle class="align-content-center p-3">
    <h2>{{ $t("contract.documents") }}</h2>
    <div v-for="(document, i) in documents" :key="i" class="d-flex row px-2">
      <span class="icon-document p-2"></span>
      <div
        class="d-flex flex-column contract-document-info"
        :data-cy="'document-' + document.id"
        @click="onDocumentInfoClick(document)"
      >
        <p class="p-1 mb-0">{{ document.created_at }}</p>
        <p class="pl-1">{{ document.filename }}</p>
      </div>
    </div>
  </GreyRectangle>
</template>

<script>
import GreyRectangle from "../base/GreyRectangle"
import DownloadDocumentMixin from "../mixins/DownloadDocumentMixin"

export default {
  name: "ContractDocuments",
  components: { GreyRectangle },
  mixins: [DownloadDocumentMixin],
  props: {
    subscription: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: false,
    },
  },
}
</script>

<style scoped>
.icon-document {
  width: 55px;
}
.contract-document-info {
  cursor: pointer;
}
</style>
