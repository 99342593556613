<template>
  <div class="container pt-5">
    <div class="row">
      <div class="col-12 mb-3">
        <h1>
          <br />
          <span>{{ $t("faq.title") }}</span>
          <br />
          <small>{{ $t("faq.subtitle") }}</small>
        </h1>
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <AccordionContainer :questions="questions"></AccordionContainer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionContainer from "../components/utils/AccordionContainer"
import Questions from "../helpers/faq-example"

export default {
  name: "Faq",
  components: { AccordionContainer },
  data() {
    return {
      questions: [],
    }
  },
  created() {
    this.questions = Questions
  },
}
</script>

<style scoped lang="scss"></style>
