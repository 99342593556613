<template>
  <div class="card">
    <div
      :id="`heading${questionKey}`"
      class="card-header d-flex justify-content-between align-items-center"
      data-toggle="collapse"
      :data-target="`#collapse${questionKey}`"
      aria-expanded="true"
      :aria-controls="`collapse${questionKey}`"
      @click.prevent="isCollapse = !isCollapse"
    >
      <h5 class="mb-0 w-faq-title">{{ questionDetails.title }}</h5>
      <span v-if="!isCollapse" class="icon-chevron-down-circle w-faq-icon"></span>
      <span v-else class="icon-chevron-up-circle w-faq-icon"></span>
    </div>

    <div
      :id="`collapse${questionKey}`"
      class="collapse"
      :aria-labelledby="`heading${questionKey}`"
      data-parent="#accordion"
    >
      <div class="card-body">
        {{ questionDetails.response }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionCard",
  props: {
    questionKey: { type: Number },
    questionDetails: { type: Object },
  },
  data() {
    return {
      isCollapse: false,
    }
  },
}
</script>
<style scoped lang="scss">
.card {
  background-color: $purple20;
  margin-bottom: 16px;
  .w-faq-title,
  .w-faq-icon {
    font-family: "SF-Pro";
    font-weight: bold;
    font-size: 17px;
    color: $blue100;
    padding: 6px 12px;
  }
  .card-header {
    cursor: pointer;
    border-radius: 4px;
    height: 69px;
  }
}
</style>
