import AbstractService from "@/services/AbstractService"
import axios from "axios"

class HttpError extends Error {
  constructor(message, response) {
    super(message)
    this.status = response.status
    this.data = response.data ? response.data : response.statusText
    this.response = response
  }
}

class Http extends AbstractService {
  /**
   * get data from url
   * @param url
   * @returns {Promise<unknown>}
   */
  get(url, responseType = {}) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.get(url, responseType)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * post data to url
   * @param url
   * @param data
   * @param headers
   * @returns {Promise<unknown>}
   */
  post(url, data, headers = {}) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.post(url, data, headers)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * put data to url
   * @param url
   * @param data
   * @returns {Promise<unknown>}
   */
  put(url, data) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.put(url, data)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * delete data from url
   * @param url
   * @param data
   * @returns {Promise<unknown>}
   */
  delete(url, data) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.delete(url, { data: { ...data } })
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }
}

let HttpService = new Http()
export { HttpService as default, HttpError }
