<template>
  <div class="rounded rectangle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GreyRectangle",
}
</script>

<style lang="scss" scoped>
.rectangle {
  background-color: $purple20;
  p {
    color: $blue60;
  }
}
</style>
