// STATES (snake_case)
const state = {
  show_warning_modal: false,
  warning_modal_info: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SHOW_WARNING_MODAL(state) {
    state.show_warning_modal = true
  },
  HIDE_WARNING_MODAL(state) {
    state.show_warning_modal = false
  },
  SET_WARNING_MODAL_INFO(state, payload) {
    state.warning_modal_info = { ...payload }
  },
  RESET_WARNING_MODAL_INFO(state) {
    state.warning_modal_info = {}
  },
}

// ACTIONS (camelCase)
const actions = {
  showWarningModal({ commit }, payload) {
    commit("SET_WARNING_MODAL_INFO", payload)
    commit("SHOW_WARNING_MODAL")
  },
  hideWarningModal({ commit }) {
    commit("RESET_WARNING_MODAL_INFO")
    commit("HIDE_WARNING_MODAL")
  },
}

// GETTERS (camelCase)
const getters = {
  mustWarningModalBeDisplayed: (state) => state.show_warning_modal,
  getWarningModalInfo: (state) => state.warning_modal_info,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
