<template>
  <div>Only for cypress testing purpose</div>
</template>

<script>
export default {
  name: "Cypress",
  async created() {
    if (window.Cypress) {
      try {
        await this.$store.dispatch("auth/acceptCookies", 1)
      } catch (e) {
        console.error("failed: ", e)
      }
    }
  },
}
</script>
