<template>
  <div :class="pgGlobalStyle" :data-cy="pgDataCy">
    <img :class="pgImgStyle" :src="product.image" :alt="product.name" />
    <div>
      <h3>
        {{ product.name }}
        <br />
        <span>{{ config.coefficient.values.coverage_km_2 }}</span>
      </h3>
      <p class="text-wrap red-had-text">{{ product.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductGuaranteed",
  props: {
    product: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: false,
    },
    pgGlobalStyle: {
      type: String,
      required: true,
    },
    pgImgStyle: {
      type: String,
      required: true,
    },
    pgDataCy: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped></style>
