<template>
  <div class="rounded home-right-div-w d-flex flex-column p-3 mb-3">
    <router-link class="nav-link" :to="{ name: linkButton }">
      <div class="d-flex flex-row justify-content-between">
        <span :class="icon"></span>
        <span class="icon-chevron-right-circle align-middle"></span>
      </div>
      <h2 class="gilroy-text">{{ titleDiv }}</h2>
      <p class="red-had-text">{{ details }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "InformativDiv",
  props: {
    icon: { type: String, required: true },
    titleDiv: { type: String, required: false },
    details: { type: String, required: true },
    linkButton: { type: String, default: "" },
  },
}
</script>

<style lang="scss" scoped>
.home-right-div-w {
  background: url("https://res.cloudinary.com/caarea/image/upload/v1624024916/cs/assets/bg-home-2_scbwbp.png");
  background-size: cover;
  filter: grayscale(60%);
  color: #fff;
  h2 {
    margin-bottom: 0;
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  div:first-child {
    margin-bottom: 18px;
  }
  p {
    color: $blue20;
  }
}
</style>
