<template>
  <li v-if="message.isBenef" class="chat-right" data-cy="beneficiary-side">
    <div class="chat-hour">
      {{ getReadTime }}
      <span v-if="message.read_date !== null" class="icon-check-square"></span>
    </div>
    <div class="chat-text">
      {{ message.content }}
      <br />
      <ul v-if="message.documents.length > 0">
        <li
          v-for="(document, i) in message.documents"
          :key="i"
          @click="onDocumentInfoClick(document)"
        >
          <a href="#">{{ document.filename }}</a>
        </li>
      </ul>
    </div>
    <div class="chat-avatar">
      <img
        src="https://www.bootdey.com/img/Content/avatar/avatar1.png"
        alt="Retail Admin"
      />
      <div class="chat-name">{{ message.user_from.first_name }}</div>
    </div>
  </li>
  <li v-else class="chat-left" data-cy="caarea-side">
    <div class="chat-avatar">
      <img
        src="https://www.bootdey.com/img/Content/avatar/avatar2.png"
        alt="Retail Admin"
      />
      <div class="chat-name">{{ message.user_from.first_name }}</div>
    </div>
    <div class="chat-text">
      {{ message.content }}
      <span v-if="message.documents.length > 0">toto</span>
    </div>
    <div class="chat-hour">
      {{ getReadTime }}
      <span v-if="message.read_date !== null" class="icon-check-square"></span>
    </div>
  </li>
</template>

<script>
import DownloadDocumentMixin from "../mixins/DownloadDocumentMixin"

export default {
  name: "MailDetails",
  mixins: [DownloadDocumentMixin],
  props: {
    message: { type: Object },
  },
  computed: {
    getReadTime() {
      if (this.message.read_date === null) {
        return new Date(this.message.created_at).toLocaleDateString()
      }
      return new Date(this.message.read_date).toLocaleDateString()
    },
  },
}
</script>

<style scoped></style>
