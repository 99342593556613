<template>
  <div :data-cy="`button-select-file-${inputId}`">
    <div v-if="isLoading">
      <span
        v-show="isLoading"
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <label
        :for="inputId"
        class="
          rounded-bottom
          d-flex
          align-items-center
          justify-content-center
          add-document--w
          mb-3
          p-3
        "
      >
        <span class="icon-file-add text-white" />
        <p class="mb-0 pl-2">{{ buttonLabel }}</p>
      </label>
      <input
        :id="inputId"
        ref="file"
        type="file"
        :name="name"
        :accept="accept"
        :data-cy="`file-input-${inputId}`"
        @change="onChange"
        @click="onClick"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectFileButton",
  props: {
    inputId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "file",
    },
    accept: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    uploadedFile: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange() {
      // Avoid emitting event if cancel was clicked
      if (this.$refs.file.files.length >= 1) {
        this.$emit("input-file", this.$refs.file.files[0])
      }
    },
    // To emit onchange event even if same file is selected
    onClick(event) {
      event.target.value = ""
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  visibility: hidden;
  position: absolute;
}

label.link:hover {
  text-decoration: none;
}

label.link {
  text-decoration: underline;
}
.add-document--w {
  background-color: $blue100;
  cursor: pointer;
  color: #fff;
}
</style>
